@import 'variablesCSS.css';

/* Reset default styles */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  overflow: break-word;
}

body {
  font-family: 'Open Sans';
  font-size: 16px;
  color: var(--smoke);
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
}

/* Hide the default up and down arrows */
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  margin: 0; 
}

input[type="number"], input[type="text"], fieldset {
  padding: 0;
}

input[type="number"]:focus, input[type="text"]:focus, select:focus {
  outline: none;
}

.checkbox-input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px var(--smoke) solid;
  border-radius: 3px;
  margin-right: .3rem;
  outline: none;
  cursor: pointer;
}

/* .checkbox-input[type="checkbox"]:checked {
  background-color: var(--smoke);
  position: relative;
}

.checkbox-input.dark-mode[type="checkbox"]:checked {
  background-color: var(--sunset);
  position: relative;
} */

input::placeholder {
  font-size: 16px;
  color: var(--thunder)
}

input[disabled]::placeholder {
  color: var(--dull);
}

input:disabled {
  background-color: var(--dull) !important;
}

button:disabled {
  color: var(--rain) !important;
  border-color: rgb(227, 227, 227) !important;
  cursor:not-allowed !important;
}

.textfield-container:has(input:disabled) {
  background-color: var(--dull);
  border: 1px var(--dull) solid;
  border-radius: 6px;
}

/* Remove default list styles */
ul, ol {
  list-style: none;
}

/* Remove default table styles */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

#root, #__next {
  isolation: isolate;
}

.option-item:checked + span {
  background-color: var(--smoke);
  color: var(--mist);
  border: var(--border-dark);
}

.option-item.dark-mode:checked + span {
  background-color: var(--sunset);
  color: var(--dark-chocolate);
  border: var(--dark-mode-border-active);
}

/* .checkbox-input:checked + .custom-checkbox {
  background-color: var(--smoke);
} */

.textfield-container:focus-within {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
}

select:first-child {
  color: var(--thunder) !important;
}

button:hover {
  border: none
}

button:not(.button-settings-btn):hover > img {
  filter: brightness(1.1);
}

button:not(.button-settings-btn):active{
  background-color: var(--smoke);
}

button {
  padding: 0
}

.button-settings-btn {
  background: none;
  border: none;
}

.button-settings-btn:hover {
  cursor: pointer;
}

.button-settings-btn:hover img{
  filter: brightness(.9);
}

.button-settings-btn:active {
  filter: invert(.8) brightness(1.5);
}