@import 'variablesCSS.css';

@media screen and (max-width: 870px) and (orientation: portrait) {
  .Layout {
    flex-direction: column !important;
  }

  .NavBar {
    height: 6vh !important;
    width: 95% !important;
    max-width: 95% !important;
    min-width: 400px !important;
    min-height: 0 !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    order: 1 !important;
    border-bottom: var(--border-style);
    border-right: none !important;
    padding-bottom: .5rem !important;
  }

  .NavLinkContainer {
    margin: 0 !important;
    margin-left: 1rem !important;
  }

  .NavLinkContainer img {
    height: 30px !important;
  }

  .MainSection {
    width: 97% !important;
    height: 400px !important;
    min-width: 400px !important;
    order: 2 !important;
    margin-top: .5rem;
  }

  .MainSection section {
    padding-top: .5rem !important;
  }

  .TextArea {
    height: 40px !important;
    font-size: .9rem !important;
  }

  .LinesNumberContainer {
    width: 15px !important;
    font-size: .9rem !important;
  }

  .TextAreaFooter {
    min-height: 70px !important;
  }

  .MainSection .Section {
    border-radius: 12px !important;
  }

  .SidebarButton {
    margin: 0 !important;
  }

  .SidebarButton img, .SidebarButton div {
    height: 80% !important;
    width: 80% !important;
    font-size: 0.8rem !important;
  }

  .FormatButtonsContainer label {
    height: 40px !important;
    width: 75% !important;
  }

  .SectionTitle {
    text-align: center;
  }

  .Aside {
    width: 95% !important;
    max-width: 95% !important;
    height: 30% !important;
    order: 3 !important;
  }

  .QuickOptions {
    min-width: 400px !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .QuickOptions label {
    width: 60% !important;
  }

  .Section .SizeOptionsRow {
    width: 60% !important;
  }

  .Section {
    width: 95% !important;
    align-items: center !important;
    min-width: 400px !important;
  }

  .Section label {
    justify-content: center !important;
  }

  .Section label button {
    width: 60% !important;
  }

  .Section .NewItemBoxContainer {
    width: 60% !important;
  }
}

@media screen and (max-height: 600px) and (orientation: landscape) {
  .SidebarButton img {
    height: 85% !important;
    width: 85% !important;
  }

  .TextArea {
    font-size: .9rem !important;
  }

  .LinesNumberContainer {
    width: 15px !important;
    font-size: .9rem !important;
  }

  .FormatButtonsContainer label {
    height: 40px !important;
  }
}

/* Infobox */
@media screen and (max-height: 800px) {
  .InfoBoxContainer {
    height: fit-content !important;
    width: fit-content !important;
    min-width: 600px !important;
    top: -30rem !important;
    padding-bottom: 1rem;
  }

  .CloseButtonIcon {
    top: -15.5rem !important;
    left: 30.5rem !important; 
  }
}

@media screen and (max-height: 563px) {
  .InfoBoxContainer {
    height: fit-content !important;
    width: fit-content !important;
    min-width: 600px !important;
    top: -25rem !important;
    padding-bottom: 1rem;
  }

  .CloseButtonIcon {
    top: -13rem !important;
    left: 30.5rem !important; 
  }

  .AboutContainer {
    font-size: .9rem !important;
  }

  .AboutContainer h1 {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 870px) {
  .InfoBoxContainer {
    top: 2rem !important;
    left: -20rem !important;
  }
}

@media screen and (max-width: 547px) {
  .InfoBoxContainer {
    width: fit-content !important;
    min-width: 50px !important;
    height: fit-content !important;
    top: 2rem !important;
    left: -15rem !important;
    right: -10rem !important;
  }

  .CloseButtonIcon {
    left: 19.5rem !important;
    top: -18.5rem !important;
  }

  .AboutContainer {
    font-size: .9rem !important;
  }

  .AboutContainer h1 {
    font-size: 18px !important;
  }
}

@media screen and (orientation: landscape) {
  .InfoBoxContainer {
    left: 2rem !important;
  }
}
  